
















































































import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class UserBankInfo extends Vue {
  public bank = {}; // 银行卡信息
  public member = {}; // 用户信息
  public withdrawal_yes = 0; // 已结算
  public withdrawal_no = 0; // 未结算
  public log = []; // balance_log（账单列表）
  public showMessage = false;
  public bankName = '';
  public place = '';

  // 获取银行卡绑定信息
  getBankInfo() {
    let _this = this;
    this.$api.request({
      url: "balance/member/bank-info",
      success(res) {
        console.log(res)
        _this.member = res.data.member_info;
        _this.bank = res.data.bank;
        _this.withdrawal_yes = res.data.withdrawal_yes;
        _this.withdrawal_no = res.data.withdrawal_no;
        _this.log = res.data.balance_log;
        _this.place = `请输入尾号${res.data.bank.bank_account.substr(-4)}的支行全称`
      }
    });
  }
  //提交支行名称
  subBankName(){
    let _this = this;
    this.$api.request({
      url: "user/bank/add-bank-branch",
      data:{
        bank_branch:this.bankName
      },
      success(res){
        _this.getBankInfo()
      }
    })
  }

  // 获取账单记录
  withdrawal() {
    let withdrawal_yes = this.withdrawal_yes;
    let withdrawal_no = this.withdrawal_no;
    if (withdrawal_yes === 0 && withdrawal_no === 0) {
      return "50%";
    } else {
      return (withdrawal_yes / (withdrawal_no + withdrawal_yes)) * 100 + "%";
    }
  }

  init() {
    this.bank = {};
    this.member = {};
    this.withdrawal_yes = 0;
    this.withdrawal_no = 0;
    this.log = [];
    this.getBankInfo();
  }
}
